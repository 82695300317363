<template>
  <v-card class="mx-auto my-3" max-width="400" min-width="300px" relative>
    <!-- add class if profile exists -->
    <v-card-title class="lead-card-title">
      <v-col v-if="userRole !== 'marketing'" cols="12" class="ma-0 pa-0">
        <Tags-And-Create
          :itsImmediate="itsImmediate(lead.created_at, lead.reviewed)"
          :getCreatedAtDate="getCreatedAtDate(lead.created_at)"
          :brex="false"
          :lead="lead"
          view="kanban"
        />
      </v-col>
      <v-col v-if="userRole !== 'marketing'" cols="12" class="ma-0 pa-0">
        <v-row class="ma-0 pa-0">
          <!-- <div v-if="!itsImmediate(lead.created_at, lead.reviewed)">
            <v-tooltip bottom max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  class="amber lighten-3 mr-2"
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small color="amber darken-2">
                    mdi-lightning-bolt
                  </v-icon>
                </v-btn>
              </template>
              <span>
                Este Lead fue atendido en menos de 5 minutos desde que ingresó
                al sistema
              </span>
            </v-tooltip>
          </div> -->
          <div>
            <div v-if="lead.contact_lead_name" class="font-weight-light">
              {{ lead.contact_lead_name }}
            </div>
            <div v-else class="font-weight-light">
              Sin Nombre
            </div>

            <div v-if="lead.contact_external_broker_id">
              <svg
                width="40"
                height="40"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 434 444.4"
                xml:space="preserve"
              >
                <g>
                  <path
                    d="M337.6,85.1c5.6,0,10.2,4.6,10.2,10.2v237.3c0,5.6-4.6,10.2-10.2,10.2H100.3c-5.6,0-10.2-4.6-10.2-10.2V95.3
    c0-5.6,4.6-10.2,10.2-10.2H337.6 M337.6,72.3H100.3c-12.7,0-23.1,10.3-23.1,23v237.3c0,12.7,10.3,23.1,23.1,23.1h237.3
    c12.7,0,23.1-10.3,23.1-23.1V95.3C360.7,82.6,350.4,72.3,337.6,72.3L337.6,72.3z"
                  />
                </g>
                <g>
                  <path
                    d="M152.3,109.9h29.6c9.7,0,17.2,1.9,22.5,5.6c5.2,3.8,7.9,9.1,7.9,16.1c0,3.8-0.9,7.2-2.7,10.1c-1.8,2.9-4.6,5.4-8.5,7.7v0.2
    c5.1,2.1,8.7,4.8,11,8.1c2.2,3.3,3.4,7.4,3.4,12.5c0,8.2-2.4,14.5-7.3,18.9c-4.9,4.4-11.8,6.6-20.7,6.6h-35.1L152.3,109.9
    L152.3,109.9z M182.9,143.6c2.9,0,5.1-0.9,6.8-2.6c1.7-1.8,2.5-4.1,2.5-7.1c0-5.9-3.9-8.8-11.7-8.8H172v18.5L182.9,143.6
    L182.9,143.6z M185.5,180.7c3.2,0,5.7-1.1,7.5-3.2c1.8-2.1,2.8-5,2.8-8.6c0-3.9-1.1-6.8-3.3-8.5c-2.2-1.8-5.8-2.6-10.8-2.6H172
    v22.9H185.5z"
                  />
                  <path
                    d="M227.4,109.9h31.5c19.7,0,29.6,8.1,29.6,24.3c0,5.6-1.1,10.4-3.4,14.4s-5.5,7-9.8,9v0.2c1.5,1.1,2.6,2.1,3.4,3.1
    s1.5,2,2,3.2l12.5,31.8h-20.9l-9.6-25.6c-0.9-2.3-2.1-3.8-3.5-4.5s-4-1.1-7.7-1.1H247V196h-19.8v-86.1H227.4z M256.7,149
    c4,0,7.1-1,9.3-3.1c2.2-2.1,3.3-5.1,3.3-9c0-7.4-4.3-11.2-12.8-11.2h-9.3V149H256.7z"
                  />
                  <path
                    d="M150.1,232.2h58.8v15.7h-39v17.3h34.9v15.7h-34.9v21.5h39v15.7h-58.8L150.1,232.2L150.1,232.2z"
                  />
                  <path
                    d="M242.8,274.2l-25.8-42h22.2l15,25.9l15-25.9h22.2l-25.8,42l26.9,43.9h-21.8l-16.5-27.4l-16.3,27.4h-22L242.8,274.2z"
                  />
                </g>
              </svg>
            </div>
          </div>
        </v-row>
      </v-col>

      <div v-else class="lead-mkt-title">
        <div>
          <v-row class="ma-0 pa-0">
            <div v-if="!itsImmediate(lead.created_at, lead.reviewed)">
              <v-tooltip bottom max-width="200">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    class="amber lighten-3 mr-2"
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small color="amber darken-2">
                      mdi-lightning-bolt
                    </v-icon>
                  </v-btn>
                </template>
                <span>
                  Este Lead fue atendido en menos de 5 minutos desde que ingresó
                  al sistema
                </span>
              </v-tooltip>
            </div>
            <div v-if="lead.contact_lead_name" class="font-weight-light">
              {{ lead.contact_lead_name }}
            </div>
            <div v-else class="font-weight-light">
              Sin Nombre
            </div>
          </v-row>

          <div class="mkt-data">
            <label class="font-weight-bold">Origen:</label>
            {{ lead.zones }}
          </div>
          <div class="mkt-data">
            <label class="font-weight-bold">Campaña:</label>
            {{ getMktByField("campaign", "Campaña") }}
            <!-- {{ mkt | mktFilter("Campaña") }} -->
          </div>
          <div class="mkt-data">
            <label class="font-weight-bold">Conjunto:</label>
            <!-- {{ mkt | mktFilter("Conjunto") }} -->
            {{ getMktByField("adSet", "Conjunto") }}
          </div>
          <div class="mkt-data">
            <label class="font-weight-bold">Anuncio:</label>
            <!-- {{ mkt | mktFilter("Anuncio") }} -->
            {{ getMktByField("ad", "Anuncio") }}
          </div>
          <div class="mkt-data">
            <label class="font-weight-bold">Formulario:</label>
            <!-- {{ mkt | mktFilter("Formulario") }} -->
            {{ getMktByField("form", "Formulario") }}
          </div>
          <div class="mkt-data">
            <label class="font-weight-bold">Observaciones:</label>
            <!-- {{ mkt | mktFilter("Formulario") }} -->
            {{ getMktObservation() }}
          </div>
        </div>
        <div>
          <small class="date-created caption font-weight-bolder">
            <!-- Registrado el: {{ createdAtDate | dateFormat(true) }} -->
            <label class="font-weight-bold">Registrado el: </label>
            {{ dateFormat(lead.created_at) }}
          </small>
          <small class="lead-lifetime title font-weight-thin caption ml-1">
            <!-- ({{ createdAtDate | friendlyDifferenceTime }}) -->
            {{ getCreatedAtDate(lead.created_at) }}
          </small>
        </div>
      </div>
      <!--v-spacer /-->
    </v-card-title>

    <v-card-text class="py-0">
      <v-col cols="12" class="mb-2">
        <v-row>
          <v-chip class="mx-1" label small>
            {{ lead.zones }}
          </v-chip>
          <v-chip
            v-if="budget"
            class="mx-1 font-weight-medium  green--text"
            label
            small
            color="green lighten-5"
          >
            {{ moneyFormatter.format(budget) }} {{ currency ? currency : "" }}
          </v-chip>
        </v-row>
      </v-col>
    </v-card-text>
    <v-card-text class="py-0" v-if="userRole !== 'marketing'">
      <v-row align="center">
        <v-col cols="12">
          <div v-if="observations_detail.message" class="uri-text text-ellips">
            {{ observations_detail.message }}
          </div>
          <div v-else class="uri-text">
            Sin observaciones
          </div>
        </v-col>
        <v-row
          v-if="observations_detail.link || observations_detail.contact_link"
          class="my-2 mx-2"
          justify="start"
          align="start"
        >
          <v-menu offset-y v-if="observations_detail.link">
            <template v-slot:activator="{ on: { click } }">
              <v-chip
                close
                small
                label
                @click="openLink(observations_detail.link)"
                color="blue darken-1"
                text-color="white"
                close-icon=""
              >
                <v-icon leftclass="mr-1" size="16">mdi-link-variant</v-icon>
                Enlace
                <v-spacer></v-spacer>
                <v-icon right size="16" @click="click">
                  mdi-dots-vertical
                </v-icon>
              </v-chip>
            </template>
            <v-list>
              <v-list-item @click="copyToClipboard(observations_detail.link)">
                <v-list-item-title>Copiar Enlace</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <div class="mx-1"></div>
          <v-menu offset-y v-if="observations_detail.contact_link">
            <template v-slot:activator="{ on: { click } }">
              <v-chip
                close
                small
                label
                @click="openLink(observations_detail.contact_link)"
                :color="
                  isWhatsappUrl(observations_detail.contact_link)
                    ? 'green darken-1'
                    : 'blue darken-1'
                "
                text-color="white"
                close-icon=""
              >
                <v-icon
                  v-if="isWhatsappUrl(observations_detail.contact_link)"
                  left
                  class="mr-1"
                  size="16"
                >
                  mdi-whatsapp
                </v-icon>
                <v-icon v-else left class="mr-1" size="16"
                  >mdi-link-variant
                </v-icon>
                Enlace de Contacto
                <v-spacer></v-spacer>
                <v-icon right size="16" @click="click">
                  mdi-dots-vertical
                </v-icon>
              </v-chip>
            </template>
            <v-list>
              <v-list-item
                @click="copyToClipboard(observations_detail.contact_link)"
              >
                <v-list-item-title>Copiar Enlace</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
      </v-row>
    </v-card-text>
    <v-card-text
      class="py-0"
      v-if="lead.phase === 'discarded' && lead.discard_observations"
    >
      <v-row align="center" no-gutters>
        <v-col
          class="body-2 font-weight-light text--disabled lead-observations"
        >
          <span class="font-weight-bolder black--text">Notas descarte:</span>
          {{ lead.discard_observations }}
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-subtitle class="lead-card-subtitle">
      <v-col cols="12" class="ma-0 pa-0">
        <v-row
          justify="space-between"
          align="center"
          class="ma-0 pa-0 mx-2 my-2"
        >
          <div class="font-size-table-row">
            <v-row justify="start" align="center">
              <div class="pa-2 blue lighten-5 rounded-circle d-inline-block">
                <v-img
                  contain
                  :src="
                    lead.contact &&
                    lead.contact.source &&
                    lead.contact.source.media
                      ? lead.contact.source.media.src
                      : ''
                  "
                  width="20px"
                  height="20px"
                >
                </v-img>
              </div>
              <div
                v-if="
                  lead.contact &&
                    lead.contact.source &&
                    lead.contact.source.name
                "
                class="mx-2"
              >
                {{ lead.contact.source.name }}
              </div>
              <div v-else class="mx-2">
                Indefinido
              </div>
            </v-row>
          </div>
          <div v-if="userRole !== 'marketing'">
            <div>
              <v-img
                class="profile-termometer-image"
                contain
                :src="getProfileImage(lead.profile)"
                max-width="35"
                max-height="35"
              ></v-img>
            </div>
          </div>
        </v-row>
      </v-col>
      <!-- <span v-if="budget"
        >|
        <span class="lead-budget"> ${{ priceFormat(budget, currency) }}</span>
      </span> -->
    </v-card-subtitle>

    <v-divider class="mx-4 mt-3"></v-divider>
    <v-card-actions>
      <v-list-item v-if="brokerName" class="px-0 ml-2">
        <v-list-item-avatar size="32">
          <v-img :src="srcBrokerImage"></v-img>
        </v-list-item-avatar>
        <v-list-item-content class="ma-0 pa-0">
          <v-list-item-title class="broker-name">
            <v-row class="ma-0 pa-0" justify="start" align="center">
              {{ brokerName.split(" ")[0] }}
            </v-row>
          </v-list-item-title>
          <v-list-item-subtitle>
            <div class="lead-num-comments">
              <v-row class="ma-0 pa-0" justify="start" align="center">
                <svg
                  class="ma-0 pa-0"
                  width="20"
                  height="20"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 2C17.1046 2 18 2.89543 18 4L4 4L4 15.1765C2.89543 15.1765 2 14.281 2 13.1765V4C2 2.89543 2.89543 2 4 2H16Z"
                    fill="#A0A2A7"
                  />
                  <path
                    d="M14 22L11.3333 19.1765H8C6.89543 19.1765 6 18.281 6 17.1765V8C6 6.89543 6.89543 6 8 6H20C21.1046 6 22 6.89543 22 8V17.1765C22 18.281 21.1046 19.1765 20 19.1765H16.6667L14 22ZM15.8046 17.1765L20 17.1765V8L8 8V17.1765H12.1954L14 19.0872L15.8046 17.1765Z"
                    fill="#A0A2A7"
                  />
                </svg>
                <div
                  v-if="lead && lead.totalComment"
                  class="mx-1 mt-2 icon-comment"
                >
                  {{ lead.totalComment }}
                </div>
                <div v-else class="mx-1 mt-2 icon-comment">
                  0
                </div>
              </v-row>
            </div></v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-else class="px-0">
        <v-list-item-content class="ma-0 pa-0">
          <v-list-item-subtitle>
            <div class="lead-num-comments">
              <v-row class="ma-0 pa-0" justify="start" align="center">
                <svg
                  class="ma-0 pa-0"
                  width="20"
                  height="20"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 2C17.1046 2 18 2.89543 18 4L4 4L4 15.1765C2.89543 15.1765 2 14.281 2 13.1765V4C2 2.89543 2.89543 2 4 2H16Z"
                    fill="#A0A2A7"
                  />
                  <path
                    d="M14 22L11.3333 19.1765H8C6.89543 19.1765 6 18.281 6 17.1765V8C6 6.89543 6.89543 6 8 6H20C21.1046 6 22 6.89543 22 8V17.1765C22 18.281 21.1046 19.1765 20 19.1765H16.6667L14 22ZM15.8046 17.1765L20 17.1765V8L8 8V17.1765H12.1954L14 19.0872L15.8046 17.1765Z"
                    fill="#A0A2A7"
                  />
                </svg>
                <div
                  v-if="lead && lead.totalComment"
                  class="mx-1 mt-2 icon-comment"
                >
                  {{ lead.totalComment }}
                </div>
                <div v-else class="mx-1 mt-2 icon-comment">
                  0
                </div>
              </v-row>
            </div></v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="px-0">
        <div class="profile-percentage">
          <v-icon color="accent" class="ml-1 mb-1">
            mdi-auto-fix
          </v-icon>
          {{ profilePercentage }} %
        </div>
      </v-list-item>
      <v-list-item class="px-0 mr-2">
        <lead-postpone-action
          :postponed="lead.postponed.date_at"
          :leadId="lead._id"
          :lead="lead"
        />

        <!-- <div v-if="expiredToday()" class="lead-postponed">
          <v-icon v-if="showPostponedIcon()" color="green">
            mdi-clock
          </v-icon>
          <small
            class="lead-postponed-time title font-weight-light caption green--text"
          >
            {{ getPostponedDate(lead.postponed.date_at) }}
          </small>
        </div>
        <div v-else class="lead-postponed">
          <v-icon
            v-if="showPostponedIcon()"
            :color="isDelayed(lead.postponed.date_at) ? 'red' : 'blue'"
          >
            mdi-clock
          </v-icon>
          <small v-if="lead.phase === 'finished'" :class="'grey--text'">
            {{ getPostponedDate(lead.postponed.date_at) }}
          </small>
          <small
            v-else
            :class="
              isDelayed(lead.postponed.date_at) ? 'red--text' : 'blue--text'
            "
          >
            {{ getPostponedDate(lead.postponed.date_at) }}
          </small>
        </div> -->
      </v-list-item>
    </v-card-actions>
    <v-list-item v-if="lead && lead.phase === 'finished'">
      <div v-if="lead && lead.phase === 'finished'">
        <v-icon color="grey" small>
          mdi-trophy
        </v-icon>
      </div>
      <div
        v-if="lead && lead.phase === 'finished'"
        class="px-2 caption grey--text"
      >
        Fecha de cierre:
      </div>
      <div v-if="lead && lead.phase === 'finished'" class="caption">
        {{ getSpanishDate(lead.updated_at) }}
      </div>
    </v-list-item>
  </v-card>
</template>
<script>
import moment from "moment";
moment.locale("es");

export default {
  components: {
    LeadPostponeAction: () => import("@/components/lead/LeadPostpone.vue"),
    TagsAndCreate: () => import("@/components/lead/components/IconTagsLead.vue")
  },
  props: {
    lead: {
      type: Object,
      default: function() {
        return {};
      }
    },
    srcBrokerImage: {
      type: String,
      default: ""
    },
    brokerName: {
      type: String,
      default: ""
    },
    profilePercentage: {
      type: Number,
      default: 0
    },
    budget: {
      type: Number,
      default: 0
    },
    currency: {
      type: String,
      default: ""
    },
    observations: {
      type: String,
      default: ""
    },
    observations_detail: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      iconComments: require("@/assets/comments_icon.svg"),
      moneyFormatter: new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0
      }),
      publicPath: process.env.BASE_URL,
      userRole: localStorage.getItem("user_role") || ""
    };
  },
  filters: {
    // obtiene el valor de un campo en el string mkt, ejemplo:
    // mktString = Campaña: 123 | Conjunto: ABC | Anuncio: XYZ | Formulario: FB Form
    // param = (Campñana, Conjunto, Anuncio, Formulario)
    mktFilter(mktString, param) {
      // comprobar que exista el string mkt
      if (!mktString) return " - ";
      let findParam = param;
      // solo el campo Campaña no tiene '|' al principio
      if (param !== "Campaña") findParam = `| ${param}`;
      // revisar que exista el parametro en el string mkt
      const index = mktString.indexOf(findParam);
      if (index < 0) return " - ";
      let startPos = index + param.length + 1;
      if (param !== "Campaña") startPos += 2; // aumentar otros 2 por separador '|'
      // encontrar el siguiente delimitador '|'
      const indexDelimiter =
        mktString.indexOf("|", startPos) > 0
          ? mktString.indexOf("|", startPos)
          : mktString.length;
      return mktString.substring(startPos, indexDelimiter).trim();
    }
  },
  methods: {
    isWhatsappUrl(url) {
      return url.includes("rply.link");
    },
    friendlyDifferenceTime(timeToParse, allowFutureTime) {
      const format = "DD/MM/YYYY HH:mm:ss";
      let now = moment(new Date(), format);
      let time = moment(new Date(timeToParse), format);
      // retornar en caso de  trasnformar a fecha el tiempo que se mandó como parámetro
      // y se obtuvo un nan
      if (isNaN(time)) return "";

      let friendlyDifference = "";

      let diffMonths = now.diff(time, "months");
      let diffDays = now.diff(time, "days");
      let diffHours = now.diff(time, "hours");
      let diffMinutes = now.diff(time, "minutes");

      if (allowFutureTime) diffMonths = Math.abs(diffMonths);
      if (allowFutureTime) diffDays = Math.abs(diffDays);
      if (allowFutureTime) diffHours = Math.abs(diffHours);
      if (allowFutureTime) diffMinutes = Math.abs(diffMinutes);

      // si para este momento hay un error calculando la diferencia
      if (isNaN(diffMinutes)) return "";

      // en meses
      if (diffMonths > 0) {
        friendlyDifference = diffMonths + " meses";
        if (diffMonths === 1) friendlyDifference = "1 mes";
      } else {
        // en días
        if (diffDays > 0) {
          friendlyDifference = diffDays + " días";
          if (diffDays === 1) friendlyDifference = "1 día";
        } else {
          // en horas
          if (diffHours > 0) {
            friendlyDifference = diffHours + " horas";
            if (diffHours === 1) friendlyDifference = "1 hora";
          } else {
            // en minutos
            if (diffMinutes > 0) {
              friendlyDifference = diffMinutes + " min";
            } else {
              friendlyDifference = "ahora";
            }
          }
        }
      }
      return friendlyDifference;
    },
    itsImmediate(created, reviewed) {
      if (reviewed) {
        if (reviewed.is_reviewed != null && reviewed.is_reviewed) {
          var diff = Math.abs(new Date(reviewed.date_at) - new Date(created));
          const diffMinutes = Math.floor(diff / 1000 / 60);
          return diffMinutes <= 5;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    getSpanishDate(date) {
      if (date === undefined || date === null || date === "") {
        return moment(new Date())
          .locale("es")
          .format("LL");
      } else {
        return moment(new Date(date))
          .locale("es")
          .format("LL");
      }
    },
    getMktObservation() {
      if (!this.lead.hasOwnProperty("observations_detail")) {
        return "--";
      }
      if (!this.lead.observations_detail.hasOwnProperty("message")) {
        return "--";
      }
      if (this.lead.observations_detail.message === "") {
        return "--";
      }
      return this.lead.observations_detail.message;
    },
    getMktByField(fieldMarketing, fieldMkt) {
      if (this.lead.hasOwnProperty("miscellaneous")) {
        if (this.lead.miscellaneous.hasOwnProperty("marketing")) {
          if (
            this.lead.miscellaneous.marketing.hasOwnProperty(fieldMarketing)
          ) {
            return this.lead.miscellaneous.marketing[fieldMarketing];
          } else {
            return "--";
          }
        } else if (this.lead.miscellaneous.hasOwnProperty("mkt")) {
          this.mktFilter(this.lead.miscellaneous.mkt, fieldMkt);
        } else {
          return "--";
        }
      } else {
        return "--";
      }
    },
    mktFilter(mktString, param) {
      // comprobar que exista el string mkt
      if (!mktString) return " - ";
      let findParam = param;
      // solo el campo Campaña no tiene '|' al principio
      if (param !== "Campaña") findParam = `| ${param}`;
      // revisar que exista el parametro en el string mkt
      const index = mktString.indexOf(findParam);
      if (index < 0) return " - ";
      let startPos = index + param.length + 2;
      if (param !== "Campaña") startPos += 2; // aumentar otros 2 por separador '|'
      // encontrar el siguiente delimitador '|'
      const indexDelimiter =
        mktString.indexOf("|", startPos) > 0
          ? mktString.indexOf("|", startPos)
          : mktString.length;
      return mktString.substring(startPos, indexDelimiter).trim();
    },
    openLink(url) {
      window.open(url);
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
    },
    expiredToday() {
      if (
        this.lead.postponed.date_at === undefined ||
        this.lead.postponed.date_at === null
      ) {
        return false;
      } else {
        let now = new Date();
        let date = new Date(this.lead.postponed.date_at);
        if (
          now.getFullYear() === date.getFullYear() &&
          now.getMonth() === date.getMonth() &&
          now.getDate() === date.getDate()
        ) {
          if (date.getTime() > now.getTime()) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    },
    getPostponedDate(date) {
      let now = new Date();
      let leadDate = new Date(date);
      if (
        now.getDate() === leadDate.getDate() &&
        now.getMonth() === leadDate.getMonth() &&
        now.getFullYear() === leadDate.getFullYear()
      ) {
        // return "Hoy";
        return this.getCreatedAtDate(date);
      } else {
        return this.getCreatedAtDate(date);
      }
    },
    getCreatedAtDate(date) {
      if (date === undefined || date === null || date === "") {
        return new Date().getMonth() === 1
          ? "1 mes"
          : Math.round(new Date().getMonth()) + "meses";
      } else {
        let now = new Date();
        let leadDate = new Date(date);
        let oneDay = 24 * 60 * 60 * 1000;
        if (
          leadDate.getDate() === now.getDate() &&
          leadDate.getMonth() === now.getMonth() &&
          leadDate.getFullYear() === now.getFullYear()
        ) {
          // return "Hoy";
          let diffMinutes = Math.round(Math.abs((leadDate - now) / 60 / 1000));
          if (diffMinutes <= 1) {
            return diffMinutes === 0 ? "Ahora" : "1 min";
          } else {
            if (diffMinutes <= 59) {
              return Math.round(diffMinutes) + " min";
            } else {
              if (Math.round(Math.abs(diffMinutes / 60)) <= 1) {
                return "1 hora";
              } else {
                return Math.round(Math.abs(diffMinutes / 60)) + " horas";
              }
            }
          }
        } else {
          if (Math.round(Math.abs((leadDate - now) / oneDay)) < 30) {
            if (Math.round(Math.abs((leadDate - now) / oneDay)) === 1) {
              return "1 día";
            } else {
              return Math.round(Math.abs((leadDate - now) / oneDay)) + " días";
            }
          } else {
            if (this.monthDiff() > 11) {
              if (this.monthDiff() === 12) {
                return "1 año";
              } else {
                const years = Math.round(this.monthDiff() / 12);
                if (years > 1) {
                  return `${years} años`;
                } else {
                  return `${years} año`;
                }
              }
            } else {
              if (this.monthDiff() === 1) {
                return "1 mes";
              } else {
                return Math.round(this.monthDiff()) + " meses";
              }
            }
          }
        }
      }
    },
    monthDiff() {
      let start = moment(this.lead.postponed.date_at);
      let end = moment();
      if (this.isDelayed(this.lead.postponed.date_at)) {
        let diffDays = moment.duration(start.diff(end)).asDays();
        if (isNaN(diffDays)) {
          return Math.round(new Date().getMonth() + 1);
        } else {
          return Math.round(Math.abs(Math.round(diffDays / 30)));
        }
      } else {
        let diffDays = moment.duration(end.diff(start)).asDays();
        if (isNaN(diffDays)) {
          return Math.round(new Date().getMonth() + 1);
        } else {
          return Math.round(Math.abs(Math.round(diffDays / 30)));
        }
      }
    },
    urifyText(observations) {
      const formatUriText = [];
      let urlRegex = new RegExp(
        "[a-zA-Z0-9]+://([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?"
      ); //; //
      if (observations) {
        if (urlRegex.test(observations)) {
          let listText = observations.split(" ");
          for (let i = 0; i < listText.length; i++) {
            if (urlRegex.test(listText[i])) {
              if (listText[i].length > 20) {
                formatUriText.push({
                  isUrl: true,
                  url: listText[i],
                  textShow: "Enlace acortado"
                });
              } else {
                formatUriText.push({
                  isUrl: false,
                  url: listText[i],
                  textShow: listText[i]
                });
              }
            } else {
              formatUriText.push({
                isUrl: false,
                url: listText[i],
                textShow: listText[i]
              });
            }
          }
          return formatUriText;
        } else {
          formatUriText.push({
            isUrl: false,
            url: observations,
            textShow: observations
          });
          return formatUriText;
        }
      } else {
        formatUriText.push({
          isUrl: false,
          url: "",
          textShow: ""
        });
        return formatUriText;
      }
    },
    showPostponedIcon() {
      if (
        !this.postponedDate ||
        this.phase === "finished" ||
        this.phase === "discarded"
      ) {
        return false;
      } else {
        return true;
      }
    },
    isNotEmptyPostponedDate(date) {
      if (date === undefined || date === null || date === "") {
        return new Date("2019-04-03");
      } else {
        return date;
      }
    },
    isDelayed(date) {
      // date = moment(date);
      // const today = moment().format("DD-MMM-YYYY");
      // const isBefore = moment(date).isBefore(today + " 23:59:59");
      // return isBefore;
      return new Date(date).valueOf() < new Date().valueOf();
    },
    getProfileImage(profile) {
      let image = "";
      switch (profile) {
        case "A":
          image = `${this.publicPath}50rojo.png`;
          break;
        case "B":
          image = `${this.publicPath}50naranja.png`;
          break;
        case "C":
          image = `${this.publicPath}50amarillo.png`;
          break;
        case "D":
          image = `${this.publicPath}50azul.png`;
          break;
        default:
          image = `${this.publicPath}vacio.png`;
          break;
      }
      return image;
    },
    dateFormat(date) {
      if (date === undefined || date === null || date === "") {
        return moment(new Date())
          .locale("es")
          .format("LLLL");
      } else {
        return moment(new Date(date))
          .locale("es")
          .format("LLLL");
      }
    },
    priceFormat(price, currency) {
      if (parseFloat(price) <= 0 || isNaN(price)) {
        return "-";
      }
      let cu = currency;
      // si se especifico una moneda para todos los precios
      // hacer la conversion
      if (this.currencyPrices && this.currencyPrices !== "") {
        cu = this.currencyPrices;
        if (this.currencyPrices === "MXN" && currency === "USD")
          price = price * 20;
        else if (this.currencyPrices === "USD" && currency === "MXN")
          price = price / 20;
      }
      return (
        parseFloat(price)
          // .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          .concat(" " + cu)
      );
    }
  }
};
</script>
<style scoped>
.uri-text {
  display: inline-block;
  margin-left: 5px;
}

.link {
  color: blue !important;
  background-color: none;
}
.broker-avatar {
  padding: 0 !important;
  min-width: 32px !important;
  width: 32px !important;
  height: 32px !important;
}
.broker-name {
  display: block;
  font-size: 8px;
  width: 80px;
  margin-top: 2px;
}
.lead-budget {
  color: #008000;
}
.one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.profile-percentage {
  color: #ab47bc;
  width: 100%;
  text-align: center;
}
.lead-observations {
  text-align: justify;
}
.lead-num-comments {
  color: #9e9e9e;
}
.lead-postponed {
  width: 100%;
  text-align: right;
}

.lead-name {
  max-width: 258px;
}
.lead-name.has-profile {
  max-width: 222px;
}
.lead-mkt-title {
  font-size: 14px;
  font-weight: 300;
}
.text-ellips {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
